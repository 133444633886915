import React, { useState, useEffect, useRef } from "react";
import { useMedia } from 'react-use';
import "../css/style.css";
import "../css/bootstrap.min.css";
import Logo from "../img/logo.png"
import { Image, Spin, Tooltip, Modal, notification } from 'antd';
import { dynamicData } from "../helpers/data";
import { useParams } from "react-router-dom";
import { setQuestionNumber, setIsFormSubmitted, setIsLoadingScreen, setIsRequiredQuestionsAttempted } from '../redux/student/studentSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
    ClockCircleFilled,
} from '@ant-design/icons';
import SegmentInstructions from "./InstructionPage/SegmentInstructions";
import updateUserResponse from "../api/useUpdateUserResponse";


const ConfirmationModal = ( { isOpen, onOk, onCancel } ) => {
    return (
        <>
            <Modal title="Are you sure you want to submit?" open={ isOpen } onOk={ onOk } onCancel={ onCancel } okButtonProps={ { style: { backgroundColor: 'green', color: 'white' } } } okText="Submit" centered>
                <p>Once submitted, answers cannot be altered.</p>
            </Modal>
        </>
    );
};


const LeftSideBar = ( { data, formConfig, currentComponentNext, currentDynamicStepBack } ) => {
    const { client_name, user_type, grade } = useParams();

    const isRequiredQuestionsAttempted = useSelector( state => state.requiredQuestionsAttempted.isRequiredQuestionsAttempted )
    const questionNumber = useSelector( state => state.currentQuestion.questionNumber );
    const textAnswers = useSelector( ( state ) => state.answersSlice.textAnswers );
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState( null );
    const [activeQuestionIndex, setActiveQuestionIndex] = useState( null );
    const [isModalOpen, setIsModalOpen] = useState( false );

    const clientConfig = useSelector( state => state.clientConfiguration );
    const formConfigRedux = useSelector( state => state.formConfiguration );
    const isWide = useMedia( '(max-width: 500px)' );

    const formConfigData = formConfigRedux.form_config;
    //const formConfig = formConfigRedux ? formConfigRedux?.formConfig[0] : "";

    const client_id = clientConfig.client_id;

    const userAssessmentResponseID = localStorage.getItem( 'userAssessmentResponseID' );

    const { updateResponse } = updateUserResponse();

    const numberOfQuestions = data?.questions?.length || 0;

    const isQuestionNavigation = formConfig?.is_question_navigation || ( formConfigRedux?.formConfig ? formConfigRedux?.form_config.isQuestionNavigation : "" );

    const dispatch = useDispatch();
    const [flaggedQuestions, setFlaggedQuestions] = useState( [] );

    const handleButtonClick = ( index ) => {
        setSelectedQuestionIndex( index );
        dispatch( setQuestionNumber( index ) );
        localStorage.setItem( 'questionNumber', index );
    };

    const handleOk = () => {
        updateResponse( { id: userAssessmentResponseID, is_completed: 3 } )
        currentComponentNext()
        setIsModalOpen( false );
    }

    const handleCancel = () => {
        currentDynamicStepBack()
        setIsModalOpen( false );
    };


    const buttonRefs = useRef( [] );

    useEffect( () => {
        setActiveQuestionIndex( questionNumber )
        //if ( questionNumber >= 0 && questionNumber < numberOfQuestions ) {
        //    buttonRefs.current[questionNumber]?.focus();
        //    buttonRefs.current[activeQuestionIndex]?.focus();
        //}
    }, [questionNumber, numberOfQuestions, activeQuestionIndex] );

    useEffect( () => {
        const requiredQuestions = data?.questions.filter( question => question.question_optional === 'required' );
        const allRequiredAttempted = requiredQuestions?.every( ( question, index ) => textAnswers[index] );

        dispatch( setIsRequiredQuestionsAttempted( allRequiredAttempted ) );
    }, [textAnswers, data] );

    return (
        <>
            {
                isQuestionNavigation ?
                    <>
                        <div className="p-0 col-xl-3 col-lg-3 font-poppins">
                            <div className="flex flex-col justify-between h-screen p-5 gap-4">
                                <div className="h-fit flex flex-row w-full items-center">
                                    <ClockCircleFilled style={ { fontSize: '2.5rem', color: clientConfig.primary_color } } />
                                    <p className="ml-3 font-semibold w-full">Time Left : $timeRemainingSeconds</p>
                                </div>
                                <Modal
                                    title="Are you sure you want to submit?"
                                    open={ isModalOpen }
                                    onOk={ handleOk }
                                    onCancel={ handleCancel }
                                    cancelText="Go Back"
                                    okText="Submit"
                                    centered={ true }
                                    okButtonProps={ { style: { backgroundColor: '#3b82f6' } } }
                                >
                                    <p>(Once submitted, answers cannot be altered)</p>
                                </Modal>
                                <div className="flex-1 shadow-2xl rounded-md flex flex-col overflow-hidden">
                                    <div className="h-fit w-full p-2 rounded-t-md" style={ { backgroundColor: clientConfig.primary_color } }>
                                        <p className="font-normal text-xl text-white text-center">Verbal Ability and Reading Comprehension</p>
                                    </div>
                                    <div className="w-full flex flex-col h-full overflow-hidden">
                                        <div className="flex flex-wrap gap-4 bg-white p-6 h-full overflow-y-auto justify-center">
                                            { Array.from( { length: numberOfQuestions }, ( _, index ) => (
                                                <button
                                                    key={ index }
                                                    ref={ ( el ) => ( buttonRefs.current[index] = el ) }
                                                    className={ `w-14 h-14 text-blue-600 font-bold focus:outline-none
                                ${ data?.questions[index]?.question_optional === 'required'
                                                            ? textAnswers[index]
                                                                ? 'bg-gradient-to-t from-[rgba(1,128,2,0.8)] to-[rgba(167,217,44,0.8)] via-[rgba(1,128,2,0.8)] rounded-[7.47px] border border-[#98CB74] border-[0.83px] text-black'
                                                                : 'bg-red-200 text-black rounded-md'
                                                            : textAnswers[index]
                                                                ? 'bg-gradient-to-t from-[rgba(1,128,2,0.8)] to-[rgba(167,217,44,0.8)] via-[rgba(1,128,2,0.8)] rounded-[7.47px] border border-[#98CB74] border-[0.83px] text-black'
                                                                : 'bg-gradient-to-t from-[rgba(212,211,211,0.6)] via-[rgba(212,211,211,0.6)] to-[rgba(255,255,255,0.6)] text-black rounded-lg border border-[rgba(0,0,0,0.2)]'
                                                        }
                                ${ activeQuestionIndex === index ? 'ring-2 ring-offset-2 ring-offset-blue-600 ring-red' : '' }
                                ${ questionNumber === index ? 'focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-600 focus:ring-red' : '' }
                                ${ flaggedQuestions.includes( index ) ? 'bg-purple-gradient-review-button' : '' }` }
                                                    onClick={ () => {
                                                        handleButtonClick( index );
                                                        setSelectedQuestionIndex( index );
                                                    } }
                                                >
                                                    { index + 1 }
                                                </button>
                                            ) ) }
                                        </div>
                                        <div className="bg-white px-6 pt-6 border-t-2">
                                            <div className="w-full flex flex-row gap-4 justify-center ">
                                                <button className="px-3 py-2 bg-gradient-to-b from-[#F6F2F3] to-[#B1ADAE] text-black font-normal rounded-lg"
                                                    onClick={ () => {
                                                        setFlaggedQuestions( [...flaggedQuestions, questionNumber] );
                                                    } }
                                                >
                                                    Mark for review
                                                </button>

                                                <button className="px-3 py-2 bg-gradient-to-b from-[#F6F2F3] to-[#B1ADAE] text-black font-normal rounded-lg"
                                                    onClick={ () => {
                                                        setFlaggedQuestions(
                                                            flaggedQuestions.filter( ( q ) => q !== questionNumber )
                                                        );
                                                    } }
                                                >
                                                    Clear review
                                                </button>

                                            </div>
                                            <h2 className="text-lg font-bold mb-4 mt-4">Legend:</h2>
                                            <div className="flex w-full justify-between">
                                                <div className="flex flex-col">
                                                    <div className="flex items-center mb-2">
                                                        <div className="w-6 h-6 bg-gradient-to-t from-[rgba(1,128,2,0.8)] to-[rgba(167,217,44,0.8)] via-[rgba(1,128,2,0.8)] rounded-md mr-2"></div>
                                                        <span className="font-semibold text-black">Answered</span>
                                                    </div>
                                                    <div className="flex items-center mb-2">
                                                        <div className="w-6 h-6 bg-red-500 rounded-md mr-2"></div>
                                                        <span className="font-semibold text-black">Not Answered</span>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col">
                                                    <div className="flex items-center mb-2 text-left">
                                                        <div className="w-6 h-6 bg-purple-gradient-review-button rounded-md mr-2"></div>
                                                        <span className="font-semibold text-black">Marked for Review</span>
                                                    </div>
                                                    <div className="flex items-center mb-4 text-left">
                                                        <div className="w-6 h-6 bg-gradient-to-t from-[rgba(212,211,211,0.6)] via-[rgba(212,211,211,0.6)] to-[rgba(255,255,255,0.6)] rounded-md mr-2"></div>
                                                        <span className="font-semibold text-black">Not Visited</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="bg-green-600 text-white px-4 py-3 font-semibold text-xl rounded-b-md w-full" onClick={ () => setIsModalOpen( true ) }>Submit Test</button>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        {
                            isWide ?
                                <div style={ { backgroundColor: clientConfig.primary_color || "#3482F6" } } className='p-0 col-xl-4 col-lg-4 font-poppins'>
                                    <div className="flex flex-col justify-start items-center h-full text-white text-center pt-0 p-4">
                                        <div className="flex justify-center items-center w-1/2 h-20 bg-white rounded-b-3xl w-full">
                                            <div
                                                className="flex justify-center items-center h-[150px] sm:h-[200px] w-[250px] sm:w-[200px]"
                                                style={ { maxWidth: "100%", maxHeight: "100%" } }
                                            >
                                                <img
                                                    src={ clientConfig?.logo || formConfigData?.logo_link || dynamicData.default.default_left_section.logo_link || "" }
                                                    alt="logo"
                                                    className="max-h-full max-w-full"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div style={ { backgroundColor: clientConfig.primary_color || "#3482F6" } } className='p-0 col-xl-4 col-lg-4 font-poppins'>
                                    <div className="flex flex-col justify-start items-center h-full text-white text-center">
                                        <div className="flex justify-center items-center w-1/2 h-28 bg-white rounded-b-3xl">
                                            <div
                                                className="flex justify-center items-center lg:h-[150px] sm:h-[50px] lg:w-[250px] sm:w-[100px]"
                                                style={ { maxWidth: "100%", maxHeight: "100%" } }
                                            >
                                                <img
                                                    src={ clientConfig?.logo || formConfigData?.logo_link || dynamicData.default.default_left_section.logo_link || "" }
                                                    alt="logo"
                                                    className="max-h-full max-w-full"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-col justify-evenly mt-[2rem] w-full h-full">
                                            <img src={ formConfigData?.image_link || dynamicData.default.default_left_section?.image_link || "" } alt="main_banner_link" preview={ false } sizes="large" />
                                            <div>
                                                <h2 className="text-white text-5xl font-bold pr-8 pl-8 leading-[4rem]">{ formConfigData?.heading || dynamicData[client_id]?.default_left_section?.heading || dynamicData?.default?.default_left_section?.heading || "" }</h2>
                                                <p className="text-white text-base pr-8 pl-8 leading-[1.5rem]">{ formConfigData?.sub_heading || dynamicData[client_id]?.default_left_section?.sub_heading || dynamicData?.default?.default_left_section?.sub_heading || "" }</p>
                                            </div>
                                            <p className="text-white text-sm">{ formConfigData?.additional_text || dynamicData?.default?.default_left_section?.additional_text || "" }</p>
                                        </div>
                                    </div>
                                </div>
                        }
                    </>
            }
        </>
    )
};

export default LeftSideBar;
