import React, { useEffect, useState } from 'react';
import LeftSideBar from '../LeftSideBar';
import { Spin, Tooltip } from 'antd';
import useFetchFormsByUserId from '../../api/FetchFormsUserId';
import { useParams, Link, useLocation } from 'react-router-dom';
import { CheckCircleFilled, CloseCircleFilled, LockFilled } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { setFormConfiguration } from '../../redux/student/studentSlice';
import InstructionModal from './InstructionModal';

const TestSelection = () => {

    const { response: formsData, isLoading: formsdataLoading, FetchUserForms } = useFetchFormsByUserId();
    const { client_name } = useParams();

    const [isModalOpen, setIsModalOpen] = useState( false );
    const [currentTestId, setCurrentTestId] = useState( null );
    const [videoCompletionStatus, setVideoCompletionStatus] = useState( {} );

    const openModal = ( testId ) => {
        setCurrentTestId( testId );
        setIsModalOpen( true );
    };

    const closeModal = () => {
        setIsModalOpen( false );
        setCurrentTestId( null );
    };

    const setIsVideoCompleted = ( testId, status ) => {
        setVideoCompletionStatus( prevState => ( {
            ...prevState,
            [testId]: status
        } ) );
    };

    const location = useLocation();
    const query = new URLSearchParams( location.search );
    const user_email = query.get( 'user_email' );
    const user_name = query.get( 'user_name' );
    const user_id = query.get( 'user_id' );
    const dispatch = useDispatch();

    useEffect( () => {
        sessionStorage.clear();
        Object.keys( localStorage ).forEach( ( key ) => {
            if ( key !== 'student_roll_number' && key !== 'user_id' ) {
                localStorage.removeItem( key );
            }
        } );
    }, [] )

    useEffect( () => {
        if ( formsData ) {
            localStorage.setItem( 'user_id', formsData.user_id );
        }
    }, [formsData] )

    useEffect( () => {
        if ( client_name ) {
            FetchUserForms( client_name, user_id, 'test' )
            dispatch( setFormConfiguration( '' ) );
        }
    }, [client_name] )

    return (
        <div className="container-fluid">
            <div className="row row-height">
                {
                    formsdataLoading &&
                    <div className="overlay">
                        <Spin size="large" />
                    </div>
                }
                <LeftSideBar />
                <div className="col-xl-8 col-lg-8 content-right" id="start">
                    <>
                        <div className="flex items-center justify-center w-3/4 flex-col">
                            <div className='border-2 w-full rounded-t-xl overflow-hidden'>
                                <table className="table-auto w-full">
                                    <thead>
                                        <tr className='bg-gray-700 text-white text-xl font-semibold'>
                                            <th className="text-center px-4 py-2 border-r-2">S.no</th>
                                            <th className="text-center px-4 py-2 border-r-2">Test Name</th>
                                            <th className="text-center px-4 py-2 border-r-2">Test Links</th>
                                            <th className="text-center px-4 py-2 border-r-2">Instructions</th>
                                            <th className="text-center px-4 py-2">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { formsData?.forms?.map( ( test, index ) => (
                                            <React.Fragment key={ test.test_id }>
                                                { currentTestId === test.test_id && (
                                                    <InstructionModal
                                                        isModalOpen={ isModalOpen }
                                                        handleCancel={ closeModal }
                                                        setIsVideoCompleted={ ( testId, status ) => setIsVideoCompleted( testId, status ) }
                                                        youtubeURL="https://youtu.be/cxQMLCtoi_w?si=TNwmLEZHeXtqNft8"
                                                        testId={ test.test_id }
                                                    />
                                                ) }
                                                <tr>
                                                    <td className="px-4 py-3 text-center text-xl border-r-2">{ index + 1 }</td>
                                                    <td className="px-4 py-3 text-center text-xl border-r-2">{ test.form_name }</td>
                                                    <td className="px-4 py-3 text-center text-xl border-r-2">
                                                        <Tooltip
                                                            title={ ( test.is_completed !== 3 && !videoCompletionStatus[test.test_id] ) ? "Please watch the Instructions" : test.is_completed === 3 ? "You've already given the test" : "" }
                                                            placement='bottom'
                                                            arrow={ false }>
                                                            <button
                                                                type='button'
                                                                className={ `text-white px-2 rounded-lg py-2 ${ ( test.is_completed === 3 || !videoCompletionStatus[test.test_id] ) ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-500' }` }
                                                                style={ { cursor: ( test.is_completed === 3 || !videoCompletionStatus[test.test_id] ) ? 'not-allowed' : 'pointer' } }
                                                                onClick={ () => {
                                                                    if ( test.is_completed !== 3 && videoCompletionStatus[test.test_id] ) {
                                                                        window.location.href = `/${ client_name }/test/${ test.test_id }/?user_email=${ user_email }&user_name=${ user_name }&user_id=${ user_id }`;
                                                                    }
                                                                } }
                                                            >
                                                                Take Test
                                                            </button>
                                                        </Tooltip>
                                                    </td>
                                                    <td className="px-4 py-3 text-center text-xl border-r-2">
                                                        <button
                                                            onClick={ () => openModal( test.test_id ) }
                                                            className='text-white bg-blue-500 px-2 rounded-lg py-2'
                                                        >
                                                            Instructions
                                                        </button>
                                                    </td>
                                                    <td className="px-4 py-3 text-center text-xl">
                                                        { test.is_completed === 3 ? (
                                                            <CheckCircleFilled style={ { color: 'green' } } />
                                                        ) : (
                                                            <CloseCircleFilled style={ { color: 'red' } } />
                                                        ) }
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ) ) }
                                    </tbody>
                                </table>
                            </div>
                            { formsData?.forms?.every( ( test ) => test.is_completed === 3 ) ? (
                                <Link
                                    to={ `/${ client_name }/thank_you/?completed=true` }
                                >
                                    <button className="text-white text-center align-center bg-blue-500 px-2 rounded-lg py-2 mt-4 text-xl">View My Report</button>
                                </Link>
                            ) : (
                                <div className="flex items-center justify-center mt-4">
                                    <Tooltip title="Please complete all the tests" placement='bottom' arrow={ false }>

                                        <button
                                            className="text-black bg-yellow-300 opacity-50 px-2 rounded-lg py-2 text-xl text-center align-center">
                                            <LockFilled style={ { color: 'black' } } className='mr-2 align-middle' />
                                            View My Report
                                        </button>
                                    </Tooltip>
                                </div>
                            ) }
                        </div>
                    </>
                </div>
            </div>
        </div>
    );
};

export default TestSelection
