import React, { useState, useEffect } from "react";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { dynamicData } from "../../helpers/data";
import SubmitButton from "../SubmitButton/SubmitButton";
import { useSelector } from 'react-redux';

const EmailTypeStep = ( { question, answer, onAnswerChange, onNext, onBack, isPagination, isQuestionNavigation, isValidation } ) => {
    const [isAnswerFilled, setIsAnswerFilled] = useState( false );
    const [email, setEmail] = useState( "" );
    const [showError, setShowError] = useState( false );
    const clientConfig = useSelector( state => state.clientConfiguration );

    const handleAnswerChange = ( value ) => {
        onAnswerChange( value );
        setEmail( value );
        setIsAnswerFilled( value.trim() !== "" );

        if ( question.text.includes( "Please provide coordinator's email id" ) ) {
            localStorage.setItem( 'contact_person_email', value );
        } else if ( question.text.includes( "Please provide your email id" ) ) {
            localStorage.setItem( 'studentEmail', value );
        } else if ( question.text.includes( "Please provide your email address so that you don’t lose progress" ) ) {
            localStorage.setItem( 'studentEmail', value );
        }
    };

    useEffect( () => {
        setEmail( answer );
    }, [question] );

    useEffect( () => {
        if ( answer ) {
            setIsAnswerFilled( true )
            setEmail( answer );
        } else {
            setIsAnswerFilled( false )
        }
    }, [question, answer] )

    const isValidEmail = ( value ) => {
        const regex = /^[a-zA-Z0-9]+([\.-]?[a-zA-Z0-9]+){0,5}@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]+$/;
        return regex.test( value );
    };

    useEffect( () => {
        if ( isValidEmail( email ) ) {
            isValidation( true )
        } else {
            isValidation( false )
        }
    }, [email] )

    const perQuestionTimer = dynamicData.default.perQuestionTimer;

    const handleKeyDown = ( e ) => {
        if ( e.key === "Enter" ) {
            onNext();
        }
    };

    const handleBlur = () => {
        if ( !isValidEmail( email ) ) {
            setShowError( true );
        } else {
            setShowError( false );
        }
    };

    return (
        <div className="flex flex-col mt-2 justify-between h-full">
            <div className="flex flex-col">
                <QuestionAndDescription question={ question } />
                <div>
                    <input
                        className="w-1/2 sm:w-full lg:w-1/2 rounded bg-white p-3 border-2 focus:border-blue-500"
                        style={ { resize: "none", textAlign: "left", } }
                        value={ email }
                        onChange={ ( e ) => {
                            //setEmail( e.target.value );
                            handleAnswerChange( e.target.value );
                        } }
                        placeholder={ question?.placeholder_text }
                        onKeyDown={ handleKeyDown }
                        onBlur={ handleBlur }
                    />
                    {
                        showError &&
                        <p className="text-red-500 text-xs left-0 mt-2">
                            Invalid email format. Please enter a valid email.
                        </p>
                    }
                </div>
            </div>
            {
                isPagination ?
                    <></>
                    :
                    isQuestionNavigation ?
                        <>
                            <SubmitButton onNext={ onNext } />
                        </>
                        :
                        <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
                            {
                                question?.question_optional === "optional" && (
                                    <button className="skip-button" onClick={ onNext } >
                                        Skip
                                    </button>
                                )
                            }
                            {
                                perQuestionTimer ?
                                    <></> :
                                    <button className="back-button" onClick={ onBack } >
                                        Back
                                    </button>

                            }
                            <button
                                className="next-button"
                                onClick={ () => {
                                    if ( isValidEmail( email ) ) {
                                        onNext();
                                    }
                                } }
                                style={ {
                                    backgroundColor: !isValidEmail( email ) ? `${ clientConfig.primary_color }80` : `${ clientConfig.primary_color }`,  
                                    cursor: !isValidEmail( email ) ? 'not-allowed' : 'pointer'
                                } }
                            >
                                Next
                            </button>
                        </div>
            }
        </div>
    );
};

export default EmailTypeStep;
