import React from 'react';
import { dynamicData } from '../../helpers/data';
import { useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';

export const ThankYouGlobalCareer = () => {
    const formConfigRedux = useSelector( state => state.formConfiguration );
    const { client_name } = useParams();

    const location = useLocation();
    const query = new URLSearchParams( location.search );
    const isAllTestCompleted = query.get( 'completed' );
    const userEmail = query.get( 'user_email' );
    const userName = query.get( 'user_name' );
    const userId = query.get( 'user_id' );

    const formConfig = formConfigRedux?.form_config;
    const clientid = formConfig?.client_id;

    let thankYouPage =
        formConfig?.main_test_content?.thank_you_page ||
        dynamicData[clientid]?.main_test_content?.thank_you_page ||
        dynamicData.default?.main_test_content?.thank_you_page;

    let heading = thankYouPage?.heading || "";
    let sub_heading_one = thankYouPage?.sub_heading_one || "";

    return (
        <>
            {
                <div className="p-8 bg-white shadow-lg rounded-lg max-w-xl mx-auto text-center">
                    <h1 className="text-2xl font-bold mb-4">{ heading }</h1>
                    <p className="text-xl text-black font-bold mb-4">{ sub_heading_one }</p>
                    {
                        isAllTestCompleted ?
                            <button className="bg-blue-500 text-white text-center rounded py-2 px-4 mb-2 hover:bg-blue-600">
                                Contact Your Mentor
                            </button>
                            :
                            <Link
                                to={ `/${ client_name }/test/selection/?user_email=${ userEmail }&user_name=${ userName }&user_id=${ userId }` }
                            >
                                <button className="bg-blue-500 text-white text-center rounded py-2 px-4 mb-2 hover:bg-blue-600">
                                    Go To Test Sections
                                </button>
                            </Link>
                    }
                </div>
            }
        </>
    );
};
