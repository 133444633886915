import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';

const SubmitButton = ( { onNext, onClearAll } ) => {
    const clientConfig = useSelector( state => state.clientConfiguration );

    return (
        <>
            <div className="flex flex-row gap-2 justify-between mb-8 mt-[3rem]">
                <button
                    className="skip-button"
                    onClick={ onClearAll }
                >
                    Clear Response
                </button>
                <button
                    className="next-button"
                    onClick={ onNext }
                    style={ {
                        backgroundColor: clientConfig.primary_color,
                    } }
                >
                    Save and Next
                </button>
            </div>
        </>
    )
}

export default SubmitButton;