import React, { useEffect, useState } from "react";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { useParams } from 'react-router-dom';
import { dynamicData } from "../../helpers/data";
import SubmitButton from "../SubmitButton/SubmitButton";
import { useSelector } from 'react-redux';

const DragDropList = ( { options, orderedOptionsList } ) => {
    const [draggedIndex, setDraggedIndex] = useState( null );
    const [items, setItems] = useState();
    const [orderedOptions, setOrderedOptions] = useState();

    const handleDragStart = ( e, index ) => {
        setDraggedIndex( index );
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData( 'text/plain', index.toString() );
    };

    useEffect( () => {
        if ( options ) {
            setItems( options )
            setOrderedOptions( options.map( ( item ) => item.id ) );
        }
    }, [options] )

    useEffect( () => {
        orderedOptionsList( orderedOptions );
    }, [orderedOptions] )

    const handleDragOver = ( e, index ) => {
        e.preventDefault();
        const draggedOverItem = items[index];

        if ( draggedIndex === index ) return;

        const newItems = items.filter( item => item !== items[draggedIndex] );
        newItems.splice( index, 0, items[draggedIndex] );

        setItems( newItems );
        setOrderedOptions( newItems.map( ( item ) => item.id ) )
        setDraggedIndex( index );
    };

    const handleDragEnd = () => {
        setDraggedIndex( null );
    };

    return items && (
        <div className="flex flex-col space-y-2">
            { items.map( ( item, index ) => (
                <div className="flex flex-row gap-2" key={ item.id }>
                    <div className="p-3 mr-1 border rounded-md">{ index + 1 }</div>
                    <div
                        className={ `flex items-center p-3 border border-gray-300 rounded-md ${ draggedIndex === index ? 'bg-blue-200' : '' }` }
                        draggable
                        onDragStart={ ( e ) => handleDragStart( e, index ) }
                        onDragOver={ ( e ) => handleDragOver( e, index ) }
                        onDragEnd={ handleDragEnd }
                    >
                        <div className="mr-4 cursor-move">&#9776;</div> {/* Drag icon */ }
                        { item.value }
                    </div>
                </div>
            ) ) }
        </div>
    );
};
const OrderTypeQuestion = ( {
    question,
    onSelect,
    onNext,
    onBack,
    selectedOptionsFromParent,
    isPagination,
    isQuestionNavigation,
    isValidation,
} ) => {
    const [selectedOptions, setSelectedOptions] = useState(
        selectedOptionsFromParent || []
    );
    const clientConfig = useSelector( state => state.clientConfiguration );

    const [inputValue, setInputValue] = useState( 0 );

    const { client_name } = useParams();

    const perQuestionTimer = dynamicData.default.perQuestionTimer;

    const handleClearAll = () => {
        setSelectedOptions( [] ); // Clear the selected options
        onSelect( [] ); // Notify the parent component about the cleared options
    };

    const handleNext = () => {
        onNext();
        isValidation( true );
    };

    return (
        <div className="flex flex-col mt-2 justify-between h-full">
            <div className="flex flex-col">
                <QuestionAndDescription question={ question } />
                <div className="justify-content space-between w-full mt-5 mb-5">
                    <DragDropList
                        options={ question?.options }
                        orderedOptionsList={ ( orderList ) => onSelect( orderList ) }
                    />
                </div>
            </div>
            { isPagination ? (
                <></>
            ) : isQuestionNavigation ? (
                <>
                    <SubmitButton onNext={ onNext } onClearAll={ handleClearAll } />
                </>
            ) : (
                <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
                    { question?.question_optional === 'optional' && (
                        <button
                            className="skip-button"
                            onClick={ onNext }
                        >
                            Skip
                        </button>
                    ) }
                    {
                        perQuestionTimer ?
                            <></> :
                            <button className="back-button" onClick={ onBack } >
                                Back
                            </button>

                    }
                    <button
                        className="next-button"
                        onClick={ handleNext }
                    >
                        Next
                    </button>
                </div>
            ) }
        </div>
    );
};

export default OrderTypeQuestion;
