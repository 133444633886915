import React, { useState, useEffect } from 'react';

const PerQuestionTimer = ( { testTimeInSeconds, currentDynamicStep, timeCompleted } ) => {
    const [endTime, setEndTime] = useState( Date.now() + testTimeInSeconds * 1000 );
    const [timeLeft, setTimeLeft] = useState( testTimeInSeconds );

    useEffect( () => {
        const interval = setInterval( () => {
            setEndTime( Date.now() + testTimeInSeconds * 1000 );
            setTimeLeft( testTimeInSeconds );
            const currentTime = Date.now();
            const newTimeLeft = Math.max( endTime - currentTime, 0 ) / 1000;
            setTimeLeft( newTimeLeft );

            if ( newTimeLeft === 0 ) {
                clearInterval( interval );
                timeCompleted( true )
            }
        }, 0 );

        return () => clearInterval( interval );
    }, [currentDynamicStep] );

    console.log( 'timeLeft', timeLeft )
    console.log( 'endTime', endTime )


    const timerColor = timeLeft < 600 ? 'text-red-600' : 'text-green-600'; // Less than 10 minutes

    const formatTime = () => {
        const minutes = Math.floor( timeLeft / 60 );
        const seconds = Math.floor( timeLeft % 60 ); // Round down to the nearest whole number
        return `${ minutes }:${ seconds < 10 ? '0' : '' }${ seconds }`;
    };

    return (
        <div className="flex justify-center items-center">
            <div className="p-6 max-w-sm mx-auto bg-white rounded-xl shadow-md flex items-center space-x-4">
                <div className="flex flex-col items-center justify-center">
                    <div className="text-xl font-medium text-black">Time Remaining</div>
                    <p className={ `text-4xl font-bold ${ timerColor } mb-0` }>{ formatTime() }</p>
                </div>
            </div>
        </div>
    );
};

export default PerQuestionTimer;
