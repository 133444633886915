import React, { useEffect } from 'react';
import { useMedia } from 'react-use';
import { Spin } from 'antd';
import "../../css/style.css";
import "../../css/bootstrap.min.css";
import { useParams } from 'react-router-dom';
import { dynamicData } from '../../helpers/data';
import SignInEmail from './SigninEmail';
import SignInRollNumber from './SigninRollNumber';
import SignInCouponCode from './SigninCouponCode';
import useFetchClientConfig from '../../api/FetchClientConfig';
import { setClientConfiguration } from '../../redux/student/studentSlice';
import { useDispatch } from 'react-redux';

const SignIn = () => {
    const isWide = useMedia( '(min-width: 800px)' );
    const { client_name } = useParams();
    const dispatch = useDispatch();

    const { response: clientConfigData, isLoading: clientConfigLoading, fetchClientConfig } = useFetchClientConfig();
    const clientConfiguration = sessionStorage.getItem( 'clientConfiguration' );

    useEffect( () => {
        if ( client_name && !clientConfiguration ) {
            fetchClientConfig( client_name );
        }
    }, [client_name] )

    useEffect( () => {
        if ( clientConfigData && clientConfigData?.data ) {
            dispatch( setClientConfiguration( clientConfigData.data ) );
            sessionStorage.setItem( 'clientConfiguration', JSON.stringify( clientConfigData.data ) );

            const timeout = setTimeout( () => {
                sessionStorage.removeItem( 'clientConfiguration' );
                fetchClientConfig( client_name );
            }, 10 * 60 * 1000 );
            return () => clearTimeout( timeout );
        }
    }, [clientConfigData, client_name] );

    const SignInWithEmail = false;
    const SignInWithCouponCode = true;

    return (
        <div>
            {
                clientConfigLoading &&
                <div className="overlay">
                    <Spin size="large" />
                </div>
            }
            {
                !isWide ?
                    <div className="flex items-center justify-center h-screen">
                        <img src='https://i.ibb.co/6spSrPQ/mobile-viewing.png' alt='optimised' class="max-w-full max-h-full" />
                    </div>
                    :
                    <>
                        {
                            SignInWithEmail ?
                                <>
                                    <SignInEmail />
                                </> :
                                SignInWithCouponCode ?
                                    <>
                                        <SignInCouponCode />
                                    </> :
                                    <>
                                        <SignInRollNumber />
                                    </>
                        }
                    </>
            }
        </div>
    );
};

export default SignIn;




