import React, { useState, useEffect } from 'react';
import { notification, Spin } from 'antd';
import "../../css/style.css";
import "../../css/bootstrap.min.css";
import { useParams, useNavigate, Link } from 'react-router-dom';
import {
    EditOutlined,
    CheckCircleOutlined
} from '@ant-design/icons';
import { setIsFormSubmitted, setIsLoadingScreen } from '../../redux/student/studentSlice';
import { useDispatch } from 'react-redux';
import { useMedia } from 'react-use';
import useFetchStudentDataEmail from '../../api/FetchStudentDetailsEmail';
import LeftSideBar from '../LeftSideBar';

const SignInCouponCode = () => {

    const { client_name } = useParams();
    const [email, setEmail] = useState( '' );
    const [isMailEditable, setIsMailEditable] = useState( false );
    const [studentVerified, setStudentVerified] = useState( true );
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isWide = useMedia( '(min-width: 800px)' );

    const { response: studentData, isLoading: studentDataLoading, error: studentDataError, FetchStudentDataEmail } = useFetchStudentDataEmail();

    const student_grade_local_storage = localStorage.getItem( 'student_grade' );
    const student_email = localStorage.getItem( 'student_email' );

    useEffect( () => {
        setEmail( student_email );
    }, [] )

    const handleEditEmail = () => {
        setIsMailEditable( true );
    }

    const handleNoEditEmail = () => {
        setIsMailEditable( false );
    }

    const handleVerify = () => {
        FetchStudentDataEmail( email, client_name )
    }

    //useEffect( () => {
    //    if ( response ) {
    //        if ( response && response.data && response.data === true ) {
    //            FetchStudentDataEmail( email, client_name )
    //            notification.success( {
    //                message: 'Yayy! Coupon Verified',
    //                description: ''
    //            } );
    //        } else {
    //            notification.info( {
    //                message: 'This coupon code is not valid',
    //                description: 'Please check your coupon code and tryb to verify again'
    //            } );
    //        }
    //    } else {

    //    }
    //}, [response] )

    useEffect( () => {
        if ( studentData && studentData?.student_code ) {
            const rollNumber = studentData?.student_code;
            localStorage.setItem( 'user_id', rollNumber )
            navigate( `/${ client_name }/test/selection/?user_email=${ studentData?.email }&user_name=${ studentData?.name }&user_id=${ studentData?.student_code }` );
        } else if ( studentData && studentData?.status_code === 404 ) {
            notification.error( {
                message: 'You need to register first.'
            } );
        } else {
        }
    }, [studentData] )

    return (
        <div>
            <div className="flex flex-col md:flex-row min-h-screen">
                {
                    studentDataLoading &&
                    <div className="overlay">
                        <Spin size="large" />
                    </div>
                }
                <LeftSideBar />
                <div className="flex justify-center items-center w-full md:w-3/5 mt-3 mb-3">
                    {/*<>
                        <div className="w-[700px] flex flex-col">
                            <p className='text-2xl text-bold text-black'>Please Enter Your Email</p>
                            <div>
                                <input
                                    type="text"
                                    inputMode="numeric"
                                    value={ email }
                                    disabled={ !isMailEditable }
                                    className={ `w-1/2 rounded ${ !isMailEditable ? "bg-gray-200" : "bg-white" } p-3 border-2 focus:border-blue-500` }
                                    style={ { resize: "none" } }
                                    onChange={ ( e ) => setEmail( e.target.value ) }
                                />
                                { !isMailEditable ?
                                    <EditOutlined
                                        onClick={ handleEditEmail }
                                        style={ { fontSize: '25px' } }
                                        className="ml-4"
                                    />
                                    :
                                    <CheckCircleOutlined
                                        onClick={ handleNoEditEmail }
                                        style={ { fontSize: '25px' } }
                                        className="ml-4"
                                    />
                                }
                            </div>
                            {
                                !studentVerified &&
                                <p className='text-lg text-black m-1'>Oops! we can't verify who you are, but you can register with us by <br /><a href='' className='text-blue-500 font bold'>Clicking Here</a></p>
                            }
                            <button
                                onClick={ handleVerify }
                                className="w-1/4 px-4 py-2 mt-10 rounded-md text-white bg-blue-500"
                            >
                                Verify
                            </button>
                        </div>
                    </>*/}
                    <div className="w-1/2 flex flex-col items-center justify-center px-6 py-8 mx-auto">
                        <div className="w-full bg-white rounded-lg shadow">
                            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
                                    Sign in to your account
                                </h1>
                                <div className="space-y-4 md:space-y-6">
                                    <div>
                                        <label for="email" className="block mb-2 text-sm font-medium text-gray-900 ">Your email</label>
                                        <input
                                            type="text"
                                            value={ email }
                                            onChange={ ( e ) => setEmail( e.target.value ) }
                                            style={ { resize: "none" } }
                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                            placeholder="name@company.com"
                                            required=""
                                        />
                                    </div>
                                    {/*<div className="flex items-center justify-between">
                                        <div className="flex items-start">
                                            <div className="flex items-center h-5">
                                                <input id="remember" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300" required="" />
                                            </div>
                                            <div className="ml-3 text-sm">
                                                <label for="remember" className="text-gray-500">Remember me</label>
                                            </div>
                                        </div>
                                        <a href="#" className="text-sm font-medium text-primary-600 hover:underline">Forgot password?</a>
                                    </div>*/}
                                    <button
                                        onClick={ handleVerify }
                                        className="w-full text-white bg-blue-500 hover:bg-blue-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                        Sign in
                                    </button>
                                    <p className="text-sm font-light text-gray-500">
                                        Don’t have an account yet?
                                        <Link to='/globalcareer/survey/Ts1xMgfZ0Fh/?institute_id=385'>
                                            <span className=" ml-1 text-lg font-medium text-primary-600">Sign up</span>
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignInCouponCode;




