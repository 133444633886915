import React from 'react';
import ThankYouPageOne from './ThankYouPageOne';

export const ThankYouJaroEdu = () => {

    const studentRoolNumber = localStorage.getItem( 'student_roll_number' );
    const userID = localStorage.getItem( 'userAssessmentResponseID' );

    const handleBooking = () => {
        window.open( `https://roi-results.jaroeducation.com/?user_id=${ userID }&report_id=${ studentRoolNumber }`, '_blank' );
    }

    const handlementorshipbooking = () => {
        window.open( 'https://book.counselling.jaroeducation.com/', '_blank' );
    }

    return (
        <>
            <ThankYouPageOne
                handleReport={ handleBooking }
                handlementorshipbooking={ handlementorshipbooking }
            />
        </>
    );
};