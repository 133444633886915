import { useState } from 'react';
import axios from 'axios';
import {
    BASE_URL, MULTIPLE_QUESTION_RESPONSES
} from '../utils/constants/constants';

const useMultipleQuestionResponses = ( batchSize ) => {
    const initialQuestionResponse = {
        "question_id": 0,
        "text_response": "",
        "time_taken": 0,
        "user_assessment_response_id": 0,
        "options": [],
        "attempted": true
    };

    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );
    const [questionResponses, setQuestionResponses] = useState( [] );

    const API_URL = `${ BASE_URL }${ MULTIPLE_QUESTION_RESPONSES }`;

    const updateQuestionResponse = ( index, updateFunction ) => {
        setQuestionResponses( prevResponses => {
            const updatedResponse = { ...prevResponses[index] };
            updateFunction( updatedResponse );
            const updatedResponses = [...prevResponses];
            updatedResponses[index] = updatedResponse;
            return updatedResponses;
        } );
    };

    const resetQuestionResponses = () => {
        setQuestionResponses( [] );
    };

    const multipleQuestionResponses = async () => {
        setIsLoading( true );
        try {
            const res = await axios.post( API_URL, questionResponses );
            setResponse( res.data );
            setError( null );
            resetQuestionResponses(); // Reset questionResponses on success
        } catch ( err ) {
            setError( err );
        } finally {
            setIsLoading( false );
        }
    };

    return { response, error, isLoading, updateQuestionResponse, multipleQuestionResponses };
};

export default useMultipleQuestionResponses;
