import React, { useState, useEffect } from 'react';
import { dynamicData } from '../../helpers/data';
import { notification, Spin } from 'antd';
import useGeneratePdfReport from '../../api/useGeneratePdfReport';
import { useSelector } from 'react-redux';
import ThankYouPageOne from './ThankYouPageOne';

export const ThankYouJenNext = () => {

    const student_name = localStorage.getItem( 'studentName' ) || "abc";
    const student_email = localStorage.getItem( 'user_email' ) || "abc@gmail.com";
    const [reportLink, setReportLink] = useState( null );
    const formConfigRedux = useSelector( state => state.formConfiguration );
    const [studentRollNumber, setStudentRollNumber] = useState( null );

    const formConfig = formConfigRedux?.form_config;
    const formid = formConfig?.form_id;

    const { data: pdfReport, isLoading: pdfReportGenerating, generatePdfReport } = useGeneratePdfReport();

    const handleBooking = () => {
        window.open( `https://jnmfreecounselling.youcanbook.me/`, '_blank' );
    }

    useEffect( () => {
        if ( studentRollNumber ) {
            generatePdfReport( studentRollNumber, formid )
        }
    }, [studentRollNumber] )

    useEffect( () => {
        setStudentRollNumber( localStorage.getItem( 'student_roll_number' ) );
    }, [] )


    const handleDownloadReport = async () => {
        if ( pdfReportGenerating ) {
            notification.info( {
                message: 'PDF Report is on it\'s way',
            } );
        }
        if ( pdfReport ) {
            const downloadLink = document.createElement( "a" );
            downloadLink.style.display = "none";
            downloadLink.href = reportLink;

            // Append the anchor element to the document body
            document.body.appendChild( downloadLink );

            // Trigger a click event on the anchor to start the download
            downloadLink.click();

            // Remove the anchor from the document
            document.body.removeChild( downloadLink );
        }
    }

    useEffect( () => {
        if ( pdfReport && reportLink === null ) {
            setReportLink( pdfReport );
        }
    }, [pdfReport] )

    return (
        <>
            {
                <ThankYouPageOne
                    handleReport={ handleDownloadReport }
                    handlementorshipbooking={ handleBooking }
                />
            }
        </>
    );
};
